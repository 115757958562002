@import url("https://fonts.googleapis.com/css?family=Cormorant+Garamond:400,600,700|Nothing+You+Could+Do|Overpass+Mono|Pinyon+Script|Playfair+Display+SC:400,700,900|Roboto+Condensed:300,400&display=swap");
/* font-family: 'Roboto Condensed', sans-serif;
font-family: 'Overpass Mono', monospace;
font-family: 'Playfair Display SC', serif;
font-family: 'Nothing You Could Do', cursive;
font-family: 'Cormorant Garamond', serif;
font-family: 'Pinyon Script', cursive; */
$basicColor: #888;
$mainColor: rgb(54, 54, 54);

* {
  box-sizing: border-box;
}
body {
  font-family: "Cormorant Garamond", serif;
  width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;
  color: $mainColor;
  scroll-snap-type: y proximity;
  overflow-x: hidden;
}
.container {
  margin: 0;
  padding: 0;
  width: 100vw;
  position: relative;
}
.main {
  min-height: calc(100%- 2%);
}

/* ------------------ Loading ----------------------*/
/* Loading animation */
.path {
  stroke: #000;
  fill: none;
  stroke-width: 2;
  stroke-dasharray: 5000;
  stroke-dashoffset: 5000;
  animation: DASH 5s ease-in-out alternate 0s forwards infinite;
  -webkit-animation: DASH 5s ease-in-out 0s forwards infinite;
  animation: DASH 5s ease-in-out 0s forwards infinite;
}

.path2 {
  animation-delay: 0.3s;
}

.path3 {
  animation-delay: 0.5s;
}

.path4 {
  animation-delay: 0.7s;
}

@keyframes DASH {
  0% {
    stroke-dashoffset: 5000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-moz-keyframes DASH {
  0% {
    stroke-dashoffset: 5000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes DASH {
  0% {
    stroke-dashoffset: 5000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}
.svg {
  width: 30%;
  margin: 8% auto;
}
.loading{
  width: 100vw;
  height: 100vh;
  position: relative;
   .loading-box{
    position: absolute;
    left: 50%;
    top: 30%;
    -webkit-transform : translate(-50%, -50%);
    transform : translate(-50%, -50%);
   }
}
/* -------------------end of Loading ----------------*/

/* ------------------ SubNav -----------------------*/
.navBar {
  transform-origin: right top;
  transform: rotate(90deg) translateX(250%) translateY(153%);
  position: absolute;
  right: 0;
  color: #888;
  z-index: 10;
}
.mobile-title {
  display: none;
}
/* ------------------ end of SubNav -------------------*/

/*------------------- Home --------------------------*/
.container-home {
  display: grid;
  grid-template-columns: 10% 30% 60%;
  // height: 100vh;
  aside {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 20%;
  }
  .fixed {
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 10;
  }
  .container-home-middle {
    width: 100%;
    padding-top: 30%;

    h1 {
      font-size: 2.5em;
      font-family: "Playfair Display SC", serif;
      letter-spacing: 0.2em;
    }

    h2 {
      letter-spacing: 0.2em;
      font-size: 1.4em;
      width: 60%;
    }
    .path {
      stroke-dasharray: 225;
      stroke-dashoffset: 226;
      animation: path_draw_0 6000ms ease-in 0ms infinite,
        path-fade 6000ms linear 0ms infinite;
    }
    @keyframes path_draw {
      100% {
        stroke-dashoffset: 1;
      }
    }

    @keyframes path-fade {
      0% {
        stroke-opacity: 1;
      }

      94.44444444444444% {
        stroke-opacity: 1;
      }

      100% {
        stroke-opacity: 0;
      }
    }

    @keyframes path_draw_0 {
      11.11111111111111% {
        stroke-dashoffset: 226;
      }

      48.333333333333336% {
        stroke-dashoffset: 0;
      }

      100% {
        stroke-dashoffset: 0;
      }
    }
    .st0 {
      fill: none;
      stroke: $basicColor;
    }
  }
  .container-home-right {
    width: 100%;
    // height: 100vh;
    position: relative;
    .bg {
      position: absolute;
      top: 15%;
      left: -9%;
      width: 100%;
      height: 77%;
      background: url('/img/strawberry.jpg');
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-size: contain;
      background-position-x: 27vw;
      -webkit-clip-path: polygon(26% 0%, 100% 0%, 100% 100%, 0% 100%);
      clip-path: polygon(26% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
  }
}
.icon {
  font-size: 1.5em;
  color: $basicColor;
  margin: 80% 0 0 3%;
}

.mobile {
  display: none;
}

/*------------------- end of Home --------------------------*/

/*-------------------- about -------------------------------*/
.container-about {
  display: flex;
  flex-direction: row;
  width: 70%;
  height: 80vh;
  margin: 0 auto;
  align-items: center;
  .left {
    .container-about-bg {
      width: 75%;
      background-color: #dac7b193;
      margin-left: -8%;
      .toshiko {
        width: 82%;
        margin-top: 19%;
        margin-left: 29%;
        margin-bottom: -21%;
      }
    }
  }
  h3 {
    font-family: "Nothing You Could Do", cursive;
    font-size: 3em;
    margin: 0;
  }
  p {
    font-size: 1.5em;
    color: $basicColor;
    letter-spacing: 0.1em;
  }

  .right {
    width: 70%;
    padding-left: 50px;
  }

  .right p {
    font-size: 1.2em;
  }

  .button a {
    font-family: "Playfair Display SC", serif;
    font-size: 0.9em;
    display: inline-block;
    text-decoration: none;
    border: 1px solid black;
    color: black;
    padding: 0.9em 1.5em;
    letter-spacing: 0.2em;
  }

  .button a:hover {
    color: white;
    background-color: rgb(190, 156, 114);
    border: inherit;
  }
}

/* ---------------------- end of about ----------------------*/

/* ------------------------- Work ---------------------------*/
.work {
  .mobile-work {
    display: none;
  }
  scroll-snap-align: start;
  position: relative;
  margin: 0 auto;
  overflow: hidden;
  white-space: nowrap;
  padding: 4% 0;

  .myWork {
    font-size: 2.3em;
    font-family: "Cormorant SC", serif;
    margin: 0 auto;
    text-align: center;
    letter-spacing: 0.2em;
  }
  .container-work {
    display: inline-block;
    margin: 0 auto;
    width: 100%;
    height: 90%;
    padding: 4%;
    img {
      width: 100%;
      margin: 0;
      padding: 0;
    }
  }

  .container-work-box {
    margin: 0 auto;
    width: 80%;
    height: 90%;
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .title {
    font-family: "Playfair Display SC", serif;
    font-size: 2em;
    letter-spacing: 0.1em;
  }

  .minitxt {
    font-size: 0.6em;
  }

  h4 {
    font-family: "Overpass Mono", monospace;
    font-size: 0.8em;
    letter-spacing: 0.1em;
    padding: 0 4%;
  }

  .work-right {
    background-color: #e2dcd7ff;
    width: 100%;
    height: 100%;
    padding: 4%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    .nr {
      font-size: 5vw;
      position: absolute;
      top: -4%;
      right: 10%;
      font-family: "Pinyon Script", cursive;
    }
  }
  .text {
    letter-spacing: 0.05em;
    line-height: 1.4em;
    white-space: initial;
  }
  .line {
    background-color: $basicColor;
    margin: 0;
    width: 1px;
    height: 1.5rem;
  }
  .title,
  h4 {
    text-align: center;
    margin: 1%;
  }
  .minitxt {
    font-size: 0.6em;
  }

  .checkbtn {
    margin: 10% 0;
    a,
    .comingSoon {
      font-family: "Roboto Condensed", sans-serif;
      font-size: 0.8em;
      display: inline-block;
      text-decoration: none;
      border: 1px solid black;
      color: rgb(54, 54, 54);
      padding: 0.8em 1.5em;
      letter-spacing: 0.4em;
    }
    a:hover {
      background-color: rgb(165, 134, 93);
      color: #fff;
    }
  }

  .arrow-right {
    display: block;
    padding: 1.25rem 1.25rem;
    position: absolute;
    top: 80%;
    left: 77%;
    cursor: pointer;
    transition-duration: 1s;

    &:hover {
      transform: translateX(2vw);
    }
    .arrow {
      position: relative;
      height: 1px;
      width: 6vw;
      background-color: $mainColor;
    }
    .arrow::after {
      position: absolute;
      content: "";
      border: solid 4px transparent;
      border-left: solid 15px $mainColor;
      top: -3.2px;
      right: -10px;
    }
  }

  .arrow-left {
    display: block;
    padding: 1.25rem 1.25rem;
    position: absolute;
    top: 80%;
    left: 52%;
    cursor: pointer;
    transition-duration: 1s;
    &:hover {
      transform: translateX(-2vw);
    }
    .arrow {
      position: relative;
      height: 1px;
      width: 6vw;
      background-color: $mainColor;
    }
    .arrow::before {
      position: absolute;
      content: "";
      border: solid 4px transparent;
      border-right: solid 15px $mainColor;
      top: -3.2px;
      left: -10px;
    }
  }
}

/* ---------------------- end of work ----------------------*/

/* ------------------------- Skills -------------------------*/
/* charts */
.skill {
  width: 100vw;
  height: 70vh;
  position: relative;
}

.doughnut {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.doughnut--chart {
  position: relative;
  width: 22em;

  .doughnut__name {
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    right: 0;
    text-align: center;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-weight: bold;
    font-size: 1.3em;
  }
}

/* ---------------------- end of Skills ----------------------*/

/* ----------------------  Contact ----------------------*/
.contact {
  height: 96vh;

  .contact-container-box {
    display: flex;
    width: 100vw;
    height: 100vh;

    .contact-left {
      width: 70vw;

      .contact-bg {
        margin: 5%;
        width: 80%;
        height: 80%;
        background: url("/img/smoothie.JPG");
        background-attachment: fixed;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 10vw -7vw;
      }
    }

    .contact-right {
      width: 30vw;
      // height: 100vh;
      position: relative;

      .box {
        width: 30vw;
        height: 30vw;
        border: 11px solid rgb(216, 214, 214);
        position: absolute;
        top: 50%;
        left: -33%;
        transform: translate(-50%, -60%);
        z-index: 1;
      }
      .contact-right-txt {
        width: 20vw;
        height: 25vh;
        padding: 9%;
        background-color: rgb(255, 255, 255);
        z-index: 2;
        font-size: 1.5em;
        position: absolute;
        top: 35%;
        left: -18%;
        transform: translate(-44%, -50%);
        text-align: center;

        .contact-mail {
          font-size: 3vh;
          position: absolute;
          top: 60%;
          left: 50%;
          transform: translate(-50%, -60%);
        }
        .contact-mail > a {
          text-align: center;
          position: relative;
          color: $mainColor;
          text-decoration: none;
          &:hover {
            color: $mainColor;
          }
          &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: 0;
            left: 0;
            background-color: $mainColor;
            visibility: hidden;
            transform: scaleX(0);
            transition: all 0.5s ease-in-out 0s;
          }
          &:hover:before {
            visibility: visible;
            transform: scaleX(1);
          }
        }
      }
    }
  }
}
/* ---------------------- end of Contact ----------------------*/

/* -------------------- Footer -----------------------*/
.footer {
  height: 2%;
}
.copyright {
  text-align: center;
  font-size: 0.7em;
  letter-spacing: 0.2em;
}
.mobile {
  display: none;
}

/* ---------------------- end of Footer ----------------- */

@media (max-width: 1600px) {
  .doughnut--chart {
    position: relative;
    width: 20em;
  }
  .container-work {
    padding: 0;
  }
  .work .container-work {
    padding: 1%;
  }
}

@media (max-width: 1400px) {
  .doughnut--chart {
    position: relative;
    width: 18em;
  }
  .navBar {
    transform: rotate(90deg) translateX(250%) translateY(120%);
  }
}

@media (max-width: 1350px) {
  // .container-home .container-home-middle {
  //   padding-top: 17%;
  // }
  .work .arrow-right {
    left: 78%;
  }
}

@media (max-width: 1250px) {
  .container-home .container-home-middle {
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.2em;
    }
  }
  .doughnut--chart .doughnut__name {
    font-size: 1.1em;
  }
}
@media (max-width: 1145px) {
  .doughnut--chart {
    width: 23vw;
  }
  .navBar {
    transform: rotate(90deg) translateX(250%) translateY(94%);
  }
  .container-about {
    .right {
      p {
        font-size: 1.1em;
      }
      .button a {
        font-size: 0.7em;
      }
    }
  }
  .work {
    .title {
      font-size: 1.7em;
    }
    h4 {
      font-size: 0.7em;
    }
    .text {
      line-height: 1.2em;
    }
    .line {
      height: 1.2rem;
    }
    .checkbtn {
      a,
      .comingSoon {
        font-size: 0.6em;
      }
    }
    .work-right {
      .nr {
        font-size: 4vw;
      }
    }
  }
  .contact .contact-container-box .contact-right .contact-right-txt {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 1024px) and (orientation: portrait){
  .skill{
    height: 34em;
  }
  .contact{
    height: 40vh;
  }
  .contact .contact-container-box .contact-left .contact-bg {
    margin: 5%;
    width: 100%;
    height: 31em;
    background-position: 6vw 0vw;
    background-attachment: inherit;
}
.contact .contact-container-box {
  height: 0;
}
.contact .contact-container-box .contact-right .contact-right-txt {
  width: 0;
  height: 0;
  padding: 9%;
  background-color:transparent; 
  z-index: 2;
  font-size: 0;
  position: absolute;
  top: 50%;
  left: -18%;
  transform: translate(-44%, -50%);
  text-align: center;
}
.contact .contact-container-box .contact-right .box {
  width: 0;
  height: 0;
  border: inherit;
}
.contact .contact-container-box .contact-right .contact-right-txt .contact-mail {
  font-size: 2vh;
  position: absolute;
  top: 809%;
  left: -622%;
}
.contact .contact-container-box .contact-right .contact-right-txt .contact-mail > a {
  text-align: center;
  position: relative;
  color: white;
  text-decoration: none;
}






}

@media (max-width: 1024px) {
  .work {
    scroll-snap-align: none;
  }
  .container-home .container-home-middle {
    padding-top: 30%;

    h1 {
      font-size: 1.7em;
    }
  }
  .container-home {
    .container-home-right {
      .bg {
        background-attachment: inherit;
        background-position-x: inherit;
      }
    }
  }
 
  .container-about {
    height: initial;
    margin: 8% auto;
  }
  .skill {
    width: 100vw;
    // height: 70vh;
  }
  .navBar {
    transform: rotate(90deg) translateX(250%) translateY(59%);
  }
}


@media (max-width: 993px) {
  .work {
    //  height: auto;
    .desktop-work {
      display: none;
    }
    .mobile-work {
      display: block;
    }
    .container-work {
      width: 80%;
      display: grid;
      grid-template-columns: 50% 50%;
      margin: 0 auto;
      padding: 0%;
    }
    .title {
      font-size: 1em;
    }
    .work-right {
      padding: 2%;
    }
    h4 {
      font-size: 0.6em;
    }

    .checkbtn {
      margin: 0;
      a,
      .comingSoon {
        font-size: 0.5em;
      }
    }
    .changeImg {
      order: 2;
    }

    .minitxt {
      font-size: 0.6em;
    }
    .text {
      font-size: 0.8em;
      margin: 3% 0 3% 0;
      line-height: inherit;
    }
  }
  .navBar {
    transform: rotate(90deg) translateX(131%) translateY(70%);
  }

  .container-home {
    height: 80vh ;
  }
}

@media screen and (max-width: 993px) and (orientation: portrait){
  .container-home{
    height: 52vh;
  }


}

@media (max-width: 896px) {
  .arrow {
    display: none;
  }

  .container-about {
    .right {
      h3 {
        font-size: 2em;
      }
      p {
        font-size: 0.9em;
      }
      .button a {
        font-size: 0.7em;
      }
    }
  }
  .container-home .container-home-right .bg{
    height: 100%;
  }
  .contact
    .contact-container-box
    .contact-right
    .contact-right-txt
    .contact-mail
    > a {
    font-size: 2.3vw;
  }
}

@media (max-width: 812px) {
  .icon {
    font-size: 1.2em;
  }
  .contact
    .contact-container-box
    .contact-right
    .contact-right-txt
    .contact-mail {
    font-size: 5vh;
  }
}

@media screen and (max-width: 768px) {
  .work {
    .container-work {
      grid-template-columns: inherit;
      grid-template-rows: 1fr;
      width: 80%;
      margin-bottom: 8%;

      img {
        height: auto;
      }

      .changeImg {
        order: inherit;
      }

      .work-right {
        padding: 6%;
      }

      .work-right p {
        font-size: 1em;
      }

      .title {
        font-size: 1.6em;
      }

      h4 {
        font-size: 0.7em;
      }

      .checkbtn a {
        font-size: 0.7em;
      }
    }
  }
  .navBar {
    display: none;
  }
  .container-about {
    width: 80%;

    .right {
      width: 150%;
      padding-left: 0;
    }
  }

  .skill {
    margin: 19% auto;
  }
  .doughnut {
    flex-wrap: wrap;
    width: 80%;
  }
  .doughnut--chart {
    width: 49%;
    margin: 4% 0px;
  }

  .mobile-title {
    display: block;
    font-size: 1.5em;
    margin: 3% auto;
    text-align: center;
    letter-spacing: 0.2em;
  }
  .icon {
    font-size: 1em;
  }

 
  .contact{
    display: none;
  }

}

@media screen and (max-width: 768px) and (orientation: portrait){
  .container-home{
    height: 39vh;
  }


}

@media screen and (max-width: 667px) {
  .container-home .container-home-middle {
    h1 {
      font-size: 1.4em;
    }
    h2 {
      font-size: 1em;
      width: 80%;
    }
  }
  .container-about .left .container-about-bg .toshiko {
    width: 110%;
  }
}

@media screen and (max-width: 568px) {
  .work .container-work .work-right p {
    font-size: 0.8em;
  }
  .work .container-work .checkbtn a {
    font-size: 0.5em;
  }
  .doughnut--chart .doughnut__name {
    font-size: 0.7em;
  }
}

@media screen and (max-width: 480px) {
  .skill{
    height: 43vh;
  }
  .skill {
    margin:0% auto;
  }
  .doughnut {
    width: 100%;
  }
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .container-home {
    grid: inherit;
    position: relative;
    margin-top: 20%;
    height: 100vh;

    .mobile-bg {
      width: 80vw;
      height: 80vh;
      background: url('/img/strawberry.jpg');
      background-repeat: no-repeat;
      background-size: cover;
      margin: 0 auto;
      position: relative;
    }

    .textOnPic {
      width: 60vw;
      height: auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fffefe;
      line-height: 1.5em;
      border-bottom: 1px solid white;
      border-top: 1px solid white;
      padding: 3%;
      z-index: 2;

      h1 {
        font-size: 1.5em;
        text-align: center;
      }
      h2 {
        font-size: 1em;
        text-align: center;
      }
    }
  }
  .footer .mobile {
    display: block;
    display: flex;
    justify-content: center;
    a {
      margin: 0 1%;
    }
  }
  .container-home .container-home-middle {
    h1 {
      font-size: 1.2em;
    }
    h2 {
      font-size: 0.7em;
    }
  }
  .container-about {
    display: inherit;
    width: 70%;
    .left {
      .container-about-bg {
        width: 47%;
        margin-left: 34%;
        .toshiko {
          width: 106%;
          margin-top: -24%;
          margin-left: 28%;
          margin-bottom: 15%;
        }
      }
    }

    .right {
      width: 100%;
      margin-top: 5%;
    }
  }
  .work .container-work .title {
    font-size: 1.3em;
  }
  .work .container-work h4 {
    font-size: 0.5em;
  }
  .work .container-work .work-right p {
    font-size: 1em;
  }
  .footer {
    font-size: 0.7em;
  }
  .container-home aside {
    display: none;
  }
}

@media screen and (max-width: 375px) {
  .container-home .textOnPic {
    padding: 0;
    h1 {
      font-size: 1.2em;
    }
    h2 {
      font-size: 0.8em;
    }
  }
  .work .container-work {
    width: 100%;
  }
}